@import '~antd/dist/antd.css';

html, body {
    background-color: #f0f2f5;
}

.layout-header {
    line-height: 10px;
}

.layout-content {
    padding: 0 50px;
}

.layout-content-main {
    background: #ffffff;
    margin-top: 30px;
    /* padding: 24px; */
    min-height: 100px;
}

.layout-footer {
    text-align: center;
}

.ant-form-item {
    margin-bottom: 10px;
}
#components-layout-demo-top-side-2 .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 28px 16px 0;
    float: left;
  }

  .site-layout-background {
    background: #fff;
  }